// 订单标发模块
import request from '@/utils/request'

// 查询初始化
export function pageInit() {
   return request({
     url: "/api/order/shipping/pageInit",
     method: "get"
   })
}

// 查询分页
export function listPage(params) {
  return request({
    url: "/api/order/shipping/listPage",
    params,
    method: "get"
  })
}

// 是否标发
export function handleIsdelivery(data) {
    return request({
      url: "/api/order/shipping/",
      data,
      method: "post"
    })
}