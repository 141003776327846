<template>
  <!-- 订单标发 -->
  <div
   class="inboundManual"
   v-loading.fullscreen.lock="loading"
   element-loading-text="请求数据中"
   element-loading-background="rgba(255, 255, 255, 0.5)"
  > 
    <div class="append">
      <div class="seach">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="'待标发'+`(${waitCount})`" name="317"></el-tab-pane>
          <el-tab-pane :label="'标发中'+`(${updatingCount})`" name="318"></el-tab-pane>
          <el-tab-pane label="标发成功" name="319"></el-tab-pane>
          <el-tab-pane :label="'标发失败'+`(${failureCount})`" name="320"></el-tab-pane>
          <el-tab-pane :label="'待更新'+`(${waitUpdateCount})`" name="321"></el-tab-pane>
          <el-tab-pane label="不标发" name="322"></el-tab-pane>
          <el-tab-pane label="全部" name="0"></el-tab-pane>
        </el-tabs>
        <!-- 筛选条件 -->
        <div style="margin-top: 20px">
          <el-row type="flex" justify="start">
            <el-select 
             multiple
             clearable
             collapse-tags
             class="el_select"
             placeholder="全部物流" 
             v-model="pageInfo.shipTrackWay" 
            >
             <el-option
               v-for="item in logisticList"
               :key="item.id"
               :label="item.trackWay"
               :value="item.id"
             ></el-option>
            </el-select>
            <el-select 
             multiple
             clearable
             collapse-tags
             class="el_select"
             placeholder="全部国家" 
             v-model="pageInfo.countryId" 
            >
             <el-option
               v-for="item in countryList"
               :key="item.id"
               :label="item.value"
               :value="item.id"
             ></el-option>
            </el-select>
            <el-select 
             clearable
             class="el_select"
             placeholder="全部店铺" 
             v-model="pageInfo.shopId" 
            >
             <el-option
               v-for="item in shopList"
               :key="item.id"
               :label="item.name"
               :value="item.id+'-'+item.marketPlaceId"
             ></el-option>
            </el-select>
            <el-select 
             multiple
             clearable
             collapse-tags
             placeholder="本地状态" 
             v-model="pageInfo.localStatus" 
             class="el_select"
            >
              <el-option
                v-for="item in localStateList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-select 
             multiple
             clearable
             collapse-tags
             class="el_select"
             placeholder="线上状态" 
             v-model="pageInfo.orderStatus" 
            >
              <el-option
               v-for="(item, index) in onlineStateList"
               :key="index"
               :value="item"
              >{{item}}</el-option>
            </el-select>
            <el-select 
             clearable
             class="el_select"
             placeholder="发货时限" 
             v-model="pageInfo.deliveryTime" 
            >
              <el-option
               v-for="item in deliveryTimeList"
               :key="item.id"
               :label="item.value"
               :value="item.id"
              ></el-option>
            </el-select>
            <!-- <el-select placeholder="标发次数" class="el_select">
              <el-option
               v-for="item in numberList"
               :key="item.id"
               :label="item.value"
               :value="item.id"
              ></el-option>
            </el-select> -->
            <el-input style="width:320px; margin-right: 15px" v-model.trim="inputValue" placeholder="请输入搜索内容">
              <el-select 
                clearable
                @clear="clearAsin"
                slot="prepend"
                v-model="selectValue" 
                placeholder="请选择" 
                style="width: 120px"
                >
                 <el-option
                  v-for="item in selectList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id">
                 </el-option>
              </el-select>
            </el-input>
            <el-button icon="el-icon-refresh" @click="handleReset">重置</el-button>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch(tabName)">查询</el-button>
          </el-row>
        </div>
        <!-- 按钮组 -->
        <el-row type="flex" justify="start" style="margin-top: 15px">
          <div v-show="activeName==317?true:false">
            <el-button type="primary" @click="handleTag(317)">标发</el-button>
            <el-button @click="handleNoTag(317)">不标发</el-button>
          </div>
          <div v-show="activeName==320?true:false">
            <el-button type="primary" @click="handleTag(320)">标发</el-button>
            <el-button @click="handleNoTag(320)">不标发</el-button>
          </div>
          <el-link 
           type="info" 
           :underline="false" 
           style="margin: 0 20px"
           v-show="multipleSelection.length==0?false:true"
          >已选{{multipleSelection.length}}条数据</el-link>
        </el-row>
        <!-- 表格区域 -->
        <div style="flex:1;overflow:auto;padding-top:10px">
          <el-table 
           border 
           :data="tableList" 
           style="width: 100%;"
           @selection-change="handleSelectionChange"
           header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
          >
            <el-table-column type="selection" align="center"></el-table-column>
            <el-table-column label="平台订单" align="center" :show-overflow-tooltip="true">
              <template slot-scope="scope"> 
                <span>{{scope.row.orderNumber}}</span>
              </template>
            </el-table-column>
            <el-table-column label="发货时限" prop="lastShipTime" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="系统单号" prop="systemNumber" align="center"></el-table-column>
            <el-table-column label="本地状态" prop="localStatus" align="center"></el-table-column>
            <el-table-column label="店铺" prop="shopName" align="center"></el-table-column>
            <el-table-column label="物流方式" prop="shipTrackWay" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="承运商" prop="trackName" align="center"></el-table-column>
            <el-table-column label="配送服务" prop="trackService" align="center"></el-table-column>
            <el-table-column label="跟踪号" prop="" align="center"></el-table-column>
            <el-table-column label="线上服务" prop="orderStatus" align="center"></el-table-column>
            <el-table-column label="标发状态" prop="status" align="center"></el-table-column>
            <el-table-column label="标发次数" prop="count" align="center"></el-table-column>
            <el-table-column label="标发时间" prop="uploadTime" align="center"></el-table-column>
          </el-table>
        </div>
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[20, 30, 50, 100]"
          :total="total"
          :page-size="pageInfo.pageSize"
          :current-page="pageInfo.current"
          style="text-align: center; margin-top: 15px"
        ></el-pagination>
        <el-dialog
          width="60%"
          title="订单详情"
          :visible.sync="orderDetailVisible"
        >
          <el-row type="flex" justify="start">
            <h4>订单号：</h4><span>{{121225}}</span>
          </el-row>
          <div class="look">
           <div class="nav">
             <el-divider content-position="left">基本信息</el-divider>
           </div>
           <div class="fn">
             <div class="lable">店铺</div>
             <div class="value">{{123}}</div>
           </div>
           <div class="fn">
             <div class="lable">买家姓名</div>
             <div class="value">{{1234}}</div>
           </div>
           <div class="fn">
             <div class="lable">买家邮箱</div>
             <div class="value">{{1345}}</div>
           </div>
           <div class="fn">
             <div class="lable">销售渠道</div>
             <div class="value">
               {{123456}}
             </div>
           </div>
          </div>
          <div class="look">
            <div class="nav">
              <el-divider content-position="left">收货地址</el-divider>
            </div>
            <div class="fn">
             <div class="lable">收件人</div>
             <div class="value">江苏省苏州市</div>
           </div>
           <div class="fn">
             <div class="lable">邮编</div>
             <div class="value">1008645623</div>
           </div>
           <div class="fn">
             <div class="lable">电话</div>
             <div class="value">1008645623</div>
           </div>
           <div class="fn">
             <div class="lable">地址</div>
             <div class="value">dfdhdfgdgd</div>
           </div>
          </div>
          <div class="look">
            <div class="nav">
              <el-divider content-position="left">订单详情</el-divider>
            </div>
            <el-table border :data="goodsList">
              <el-table-column label="商品信息" prop="" align="center"></el-table-column>
              <el-table-column label="品名/sku" prop="" align="center"></el-table-column>
              <el-table-column label="单价" prop="" align="center"></el-table-column>
              <el-table-column label="数量" prop="" align="center"></el-table-column>
              <el-table-column label="已配送" prop="" align="center"></el-table-column>
              <el-table-column label="销售收益" prop="" align="center"></el-table-column>
              <el-table-column label="税收" prop="" align="center"></el-table-column>
              <el-table-column label="采购成本" prop="" align="center"></el-table-column>
              <el-table-column label="促销费" prop="" align="center"></el-table-column>
              <el-table-column label="平台费" prop="" align="center"></el-table-column>
              <el-table-column label="其他" prop="" align="center"></el-table-column>
              <el-table-column label="测评费" prop="" align="center"></el-table-column>
            </el-table>
          </div>
          <div class="look">
            <div class="nav">
              <el-divider content-position="left">备注</el-divider>
            </div>
            <el-input 
             :rows="3" 
             v-model="tips"
             type="textarea" 
             placeholder="请输入备注" 
             style="width: 20%"
            >
            </el-input>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {
  pageInit,   // 查询初始化
  listPage,   // 查询分页 
  handleIsdelivery,  // 是否标发
}from '@/api/orderMark.js'
export default {
  data() {
    return {
      total: 0,
      loading: false,  
      tableList: [],    // 表格数据
      activeName: "317",  // 全部
      tips: "",  // 备注信息
      inputValue: "", 
      selectValue: "orderNumber", 
      tabName: "",
      selectList: [
        { id: "orderNumber", value: "平台单号" },
        { id: "systemNumber", value: "系统单号" }
      ],
      goodsList: [],  // 商品详情
      multipleSelection: [],  // table 表格多选数据
      pageInfo: {
        shopId: "",  // 店铺Id
        shipTrackWay: [],  // 物流方式
        countryId: [],     // 全部国家
        localStatus: [],   // 本地状态
        orderStatus: [],   // 线上状态
        deliveryTime: "",
        current: 1,  // 当前页为 1
        pageSize: 20,  // 每页显示 20
      },
      deliveryTimeList: [
        { id: 1, value: "1天内" },
        { id: 2, value: "2天内" },
        { id: 3, value: "3天内" },
        { id: 4, value: "超过3天" }
      ],
      orderDetailVisible: false,
      countryList: [],   // 全部国家
      logisticList: [],  // 全部物流
      shopList: [],   // 全部店铺
      localStateList: [],  // 本地状态
      onlineStateList: [], // 线上状态
      deliveryTime: [],    // 发货时限
      failureCount: 0,
      updatingCount: 0,
      waitCount: 0,
      waitUpdateCount: 0,
    }
  },
  created() {
    this.getPageInit(); 
    this.handleSearch(317);
    this.tabName = 317;  // 初始化
  },
  methods: {
    getPageInit() {
      pageInit()
       .then(res=> {
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
          //  this.statusList = res.data.data.statusList;  // tabs 切换事件
           this.localStateList = res.data.data.localStatusList;  // 本地状态
           this.onlineStateList =res.data.data.orderStatusList;  // 线上状态
           this.logisticList = res.data.data.shipTrackWayList;   // 全部物流
           this.shopList = res.data.data.shopList;  // 全部店铺
           this.countryList = res.data.data.countryList; // 国家
           res.data.data.shopList.forEach(item => {
             if(item.isSelected == 1) { 
               this.pageInfo.shopId = item.id+'-'+item.marketPlaceId
             }
           });
         }
       })
    },
    // 重置事件
    handleReset() {
      this.pageInfo.shipTrackWay = "";
      this.pageInfo.shopId = "";
      this.pageInfo.localStatus = "";  // 本地状态
      this.pageInfo.orderStatus = "";  // 线上状态
      this.pageInfo.deliveryTime = ""; // 发货时限
      this.pageInfo.countryId = "";  // 全部国家
      this.inputValue = "";
      this.selectValue = "";
    },
    handleClick(tab) {
      this.tabName = tab.name;
      if(tab.name==317) {   // 待标发
        this.handleSearch(317);
      }else if(tab.name==318) {   // 标发中
        this.handleSearch(318);
      }else if(tab.name==319) {   // 标发成功
        this.handleSearch(319);
      }else if(tab.name==320) {   // 标发失败
        this.handleSearch(320);
      }else if(tab.name==321) {   // 待更新
        this.handleSearch(321);
      }else if(tab.name==322) {   // 不标发
        this.handleSearch(322);
      }else if(tab.name==0) {     // 全部
        this.handleSearch(0);
      }
    },  
    // 查询事件
    handleSearch(statusType) {
      this.loading = true;
      // 组装请求参数 
      let TempData = "";
      if(this.selectValue == "" || this.inputValue == "") {
        TempData = null;
      }else if(this.selectValue == "systemNumber") {
        TempData = { systemNumber: this.inputValue }
      }else if(this.selectValue == "orderNumber") {
        TempData = { orderNumber: this.inputValue }
      }
      let params = {
        current: this.pageInfo.current,
        pageSize: this.pageInfo.pageSize,
        status: statusType,
        shopMarketplaceId: this.pageInfo.shopId,  // 店铺/站点
        countryId: this.pageInfo.countryId.join(','),   // 国家
        trackWayId: this.pageInfo.shipTrackWay.join(','),  // 物流方式
        localStatus: this.pageInfo.localStatus.join(','),  // 本地状态
        orderStatus: this.pageInfo.orderStatus.join(','),  // 线上状态
        lastShipTime: this.pageInfo.deliveryTime,  // 发货时限
      }
      listPage({...TempData, ...params})
       .then(res=> {
         this.loading = false;
         this.total = 0;
         this.tableList = [];
         if(res.data.code == 500) {
           this.$message.error(res.data.message)
         }else {
           this.tableList = res.data.values;
           this.total = res.data.mapInfo.total;
           this.failureCount = res.data.mapInfo.failureCount;  // 失败数量
           this.updatingCount = res.data.mapInfo.updatingCount;  
           this.waitCount = res.data.mapInfo.waitCount;  // 
           this.waitUpdateCount = res.data.mapInfo.waitUpdateCount;  // 
         }
       })
    },
    // 标发
    handleTag() {
      if(!this.multipleSelection.length) {
        this.$message.error('请选择要操作的订单');
        return false;
      }
      this.$confirm('确认对接平台标记发货', '标发', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = this.multipleSelection.map(s=> s.id);
        let data = {      // 标发
          ids: ids,
          type: 1
        }
        handleIsdelivery(data)
        .then(res=> {
          console.log(res);
          if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }else {
            this.$message.success(res.data.message);
            this.handleSearch(this.tabName);
          }
        })
      }).catch(() => {});
    },
    // 不标发
    handleNoTag() {
      if(!this.multipleSelection.length) {
        this.$message.error('请选择要操作的订单');
        return false;
      }
      this.$confirm('确认不对接平台标记发货', '不标发', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = this.multipleSelection.map(s=> s.id);
        let data = {    // 不标发
          ids: ids,
          type: 0
        } 
        handleIsdelivery(data)
        .then(res=> {
          console.log(res);
          if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }else {
            this.$message.success(res.data.message);
            this.handleSearch(this.tabName);
          }
        })
      }).catch(() => {});
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(neSize) {
      this.pageInfo.pageSize = neSize;
      this.handleSearch(this.tabName);
    },
    handleCurrentChange(newCurrent) {
      this.pageInfo.current = newCurrent;
      this.handleSearch(this.tabName);
    },

  }
}
</script>

<style lang="scss" scoped>
.append {
  height: 50px;
  display: flex;
  justify-content: flex-start;
  .seach {
    width: 100%;
    .el-table {
      margin-top: 25px;
      margin-bottom: 50px;
    }
    .el_select {  
      margin-right: 20px;
    }
    .el-pagination {
      text-align: center;
      margin-bottom: 10px;
    }
  }
}
.look {
  width: 100%;
  height: 100%;
  padding-bottom: 10%;
}
.fn { 
  width: 47%;
  height: 40px;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 2%;
  text-align: center;
}
.lable {
  width: 35%;
  height: 40px;
  float: left;
  line-height: 40px;
  border: 1px solid #e5e5e5;
  border-radius: 4px 0px 0px 4px;
}
.value {
  line-height: 40px;
  background-color: #fff;
  border: 1px solid #ccc;
  text-align: center;
  padding-left: 50px;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  border-left: none;
  color: #666666ff;
  border-radius: 0px 4px 4px 0px;
}
.nav {
  width: 100%;
  text-align: left;
  padding-left: 15px;
  line-height: 45px;
}
</style>